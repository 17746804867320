<template functional>
    <a-sub-menu :key="props.menuInfo.route">
        <span slot="title">
            <jtl-icon :type="props.menuInfo.icon"></jtl-icon><span>{{ props.menuInfo.name }}</span>
        </span>
        <template v-for="item in props.menuInfo.subMenus">
            <a-menu-item
                v-if="!item.subMenus || item.subMenus.length === 0"
                :key="item.route"
                :class="['ant-menu-item-wrapper', {'ant-menu-item-selected ant-menu-sub-item-selected': props.isMenuSelected(item.route)}]"
                @click="props.menuClick(item)"
            >
                <jtl-icon :type="item.icon"></jtl-icon>
                <span>{{ item.name }}</span>
            </a-menu-item>
            <sub-menu v-else :key="item.route" :menu-info="item" :menu-click="props.menuClick" :is-menu-selected="props.isMenuSelected" />
        </template>
    </a-sub-menu>
</template>
<script>
export default {
    // eslint-disable-next-line
    props: ['menuInfo', 'menuClick', 'isMenuSelected']
};
</script>
