var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "a-sub-menu",
    { key: _vm.props.menuInfo.route },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("jtl-icon", { attrs: { type: _vm.props.menuInfo.icon } }),
          _c("span", [_vm._v(_vm._s(_vm.props.menuInfo.name))]),
        ],
        1
      ),
      _vm._l(_vm.props.menuInfo.subMenus, function (item) {
        return [
          !item.subMenus || item.subMenus.length === 0
            ? _c(
                "a-menu-item",
                {
                  key: item.route,
                  class: [
                    "ant-menu-item-wrapper",
                    {
                      "ant-menu-item-selected ant-menu-sub-item-selected":
                        _vm.props.isMenuSelected(item.route),
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.props.menuClick(item)
                    },
                  },
                },
                [
                  _c("jtl-icon", { attrs: { type: item.icon } }),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ],
                1
              )
            : _c("sub-menu", {
                key: item.route,
                attrs: {
                  "menu-info": item,
                  "menu-click": _vm.props.menuClick,
                  "is-menu-selected": _vm.props.isMenuSelected,
                },
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }