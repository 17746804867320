















































































































































import { Component } from 'vue-property-decorator';
import { Menu, Modal } from 'ant-design-vue';
import { BaseComponent } from '@common-src/mixins/base-component';
import { UserStoreModule } from '@common-src/store/modules/user';
import CommonService from '@common-src/service/common';
import MENUS from '@common-src/json/menu3.json';
import MENUS_WROKORDER from '@common-src/json/menu-workorder.json';
import { CacheUtil } from '@common-src/model/cache-model';
import ProjectService from '@common-src/service/project';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import PRESENTED_IMAGE_SIMPLE from 'ant-design-vue/lib/empty/simple';
import { PermissionType } from '@common-src/model/enum';
import { ModuleType } from '@common-src/enum/module-type.enum';
import SubMenu from './sub-menu.vue';
import AppService from '@common-src/service3/app';
import { version } from '@/../package.json';
import { getUserInfo, getPermissions, initProject, login } from '@common-src/service3/user/user';

// const version = require('../../../package.json').version;
const OPS_URL = process.env.VUE_APP_OPS_URL;

@Component({
    components: {
        'sub-menu': SubMenu
    }
})
export default class DashboardComponent extends BaseComponent {
    /**
     * 左侧菜单收起/展开
     */
    private collapsed: boolean = false;

    /**
     * 项目列表
     */
    private projectList: Array<ProjectEntityModel> = null;

    /**
     * 颜色主题
     */
    theme: string = window.localStorage.getItem('theme') || 'light';

    newestReadList:Array<any> = [];
    timer:any = null;
    loading:boolean = false;

    created() {
        this.getPagesSettingInfo();
        // 订阅工单数量更新
        this.$eventHub.$on('DASHBOARD_INIT_PROJECT', () => {
            this.initProjectList();
        });
        // 订阅左侧菜单更新
        this.$eventHub.$on('MENU_RELOAD', () => {
            CommonService.getUserPermissions2(0);
        });
        this.initProjectList();
        this.setIntervalMessagesInfo(300);
        this.$eventHub.$on('UPDATE_MESSAGE_INFO', this.getMessagesInfo);
        this.$eventHub.$on('UPDATE_THEME', (theme) => {
            this.theme = theme;
            window.localStorage.setItem('theme', this.theme || 'light');
        });
    }
    beforeDestroy() {
        // 销毁消息中心定时器
        clearInterval(this.timer);
    }
    getPagesSettingInfo() {
        this.loading = false;
        if (JTL.moduleType !== ModuleType.EDGE && !this.IsSuperAdmin) {
            CommonService.getPageSettingInfo().then((res) => {
                this.theme = res?.themeStyle || 'light';
                UserStoreModule.SET_LOGO_URL(res?.logoUrl);
                UserStoreModule.SET_PLATFORM_NAME(res?.dispName);
                this.onThemeChange(res?.themeColor || '#2393cd');
                window.localStorage.setItem('theme', this.theme || 'light');
            }).finally(() => {
                this.loading = true;
            });
        } else {
            this.loading = true;
            this.onThemeChange('#2393cd');
            this.theme = 'light';
            UserStoreModule.SET_LOGO_URL('');
            window.localStorage.setItem('theme', 'light');
        }
    }
    getMessagesInfo() {
        // 获取最新消息五条数据
        CommonService.getNewsetMessageList().then((res) => {
            _.forEach((res as any).items, bodyItem => {
                const content = bodyItem.bodies.reduce((pre, item) => ` ${item.label}: ${item.value} ` + pre, '');
                bodyItem.content = bodyItem.summary || content || '';
                bodyItem.sendTimeDate = bodyItem.sendTime?.substr(5) || '';
            });
            this.newestReadList = (res as any).items;
        });
        // 获取未读消息数量
        CommonService.getMessagesCount().then((res) => {
            UserStoreModule.SET_UN_READ_MESSAGE_NUM(res.unreadCount);
        });
    }
    // 定时调用
    setIntervalMessagesInfo(interval?:number) {
        if (this.timer || this.IsSuperAdmin) return;
        this.getMessagesInfo();
        if (interval) {
            this.timer = setInterval(() => {
                this.getMessagesInfo();
            }, 1000 * interval);
        }
    }
    // 导航到消息中心
    navToMessageCenter() {
        this.$router.push({ path: `/message/message-center` });
    }
    get Version() {
        return version;
    }

    get OpsUrl() {
        return OPS_URL;
    }

    /**
     * 获取空项目状态图标
     */
    get EmptySimpleImage() {
        return PRESENTED_IMAGE_SIMPLE;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin(): boolean {
        return _.get(UserStoreModule.UserInfo, 'IsSuperAdmin');
    }

    /**
     * 是否是企业用户
     */
    get IsCorpAdmin(): boolean {
        return !UserStoreModule.CurrentProjectId;
    }
    /**
     * 路由平铺
     * @param path
     * @returns
     */
    flattenRoute(menus: Array<any>): any {
        return _.flatMap(menus, item => {
            if (item.subMenus && item.subMenus.length > 0) {
                return [item, ...this.flattenRoute(item.subMenus)];
            } else {
                return item;
            }
        });
    }
    /**
     * 查找多级嵌套路由
     * @param path
     * @returns
     */
    findRouteById(id: string, data: Array<any>) {
        if (!_.isEmpty(data)) {
            const result = _.find(data, r => r.id === id);
            if (result) {
                return result;
            } else {
                for (const item of data) {
                    const nestedResult = this.findRouteById(id, item.subMenus);
                    if (nestedResult) {
                        return nestedResult;
                    }
                }
            }
        }
        return null;
    }
    /**
     * 获取登陆用户的菜单
     */
    get MenuList() {
        let menus = null;
        switch (JTL.moduleType) {
            case ModuleType.WORKORDER:
                return MENUS_WROKORDER;
            default:
                menus = _.cloneDeep(MENUS);
                break;
        }
        if (this.IsSuperAdmin) {
        // 超级管理员有所有菜单的权限
        // return MENUS;
            return _.filter(menus, menu => ['dashboardPlatform', 'dashboardBaseconfig', 'dashboardManage', 'dataMiddleground', '/dashboard/index', 'dashboardThingsModel', '/admin/service-manage', '/admin/device-driver-list', '/admin/app-list', '/admin/audit-log-list-lib'].indexOf(menu.route) > -1);
        } else {
            const defaultMenus = this.flattenRoute(menus);
            const xMenus: any = [];
            _.forEach(UserStoreModule.UserPermissionList, r => {
                r.sortOrder = r.sortOrder || 0;
                const identifier = _.last(_.split(r.identifier, ':'));
                const parentMenu = this.findRouteById(r.parentId, xMenus);
                const componentMenu = _.find(defaultMenus, item => item.route === identifier);
                const newMenu = {
                    icon: _.get(componentMenu, 'icon') || '',
                    name: r.name,
                    route: _.get(componentMenu, 'route') || identifier,
                    permission: _.get(componentMenu, 'permission') || [r.type],
                    sortOrder: r.sortOrder,
                    id: r.id
                };
                if (parentMenu) {
                    // delete parentMenu.component;
                    parentMenu.subMenus = _.get(parentMenu, 'subMenus') || [];
                    parentMenu.subMenus.push(newMenu);
                    parentMenu.subMenus = _.orderBy(parentMenu.subMenus, 'sortOrder');
                } else {
                    xMenus.push({
                        ...newMenu,
                        subMenus: []
                    });
                }
            });
            menus = _.orderBy(xMenus, 'sortOrder');
            return menus;
        }

        // if (this.IsSuperAdmin) {
        // // 超级管理员有所有菜单的权限
        // // return MENUS;
        //     return _.filter(menus, menu => ['dashboardPlatform', 'dashboardBaseconfig', 'dashboardManage', 'dataMiddleground', '/dashboard/index', 'dashboardThingsModel', '/admin/service-manage', '/admin/device-driver-list', '/admin/app-list', '/admin/audit-log-list-lib'].indexOf(menu.route) > -1);
        // } else {
        //     const excludePlatformMenus = _.filter(menus, menu => menu.route !== 'dashboardPlatform');
        //     return _.filter(excludePlatformMenus, menu => {
        //     // if (UserStoreModule.UserPermissions.indexOf(menu.route) === -1 && !JTL.debug) {
        //     //     return false;
        //     // }
        //         if (!this.hasPermission(menu.route) && !JTL.debug) {
        //             return false;
        //         }
        //         menu.subMenus = _.filter(menu.subMenus, (subMenu: any) => {
        //             if (!this.hasPermission(subMenu.route) && !JTL.debug) {
        //                 return false;
        //             }
        //             subMenu.subMenus = _.filter(subMenu.subMenus, (subMenuLevel3: any) => this.hasPermission(subMenuLevel3.route) || JTL.debug);
        //             return true;
        //         });
        //         return true;
        //     });
        // }
    }

    hasPermission(code: string): boolean {
        return !!(_.find(UserStoreModule.UserPermissions, item => item.indexOf(code) > -1));
    }

    /**
     * 获取企业名称
     */
    get CorpName(): string {
        if (JTL.moduleType === ModuleType.EDGE || this.IsSuperAdmin) {
            return UserStoreModule.CorpName;
        } else {
            return UserStoreModule.PlatformName;
        }
    }
    get CurrentSelectProjectName(): string {
        return _.get(UserStoreModule.CurrentProject, 'name');
    }
    /**
     * 获取企业Logo
     */
    get Logo(): string {
    // return 'https://jdy-rd-dev.oss-cn-hangzhou.aliyuncs.com/workflow/65165826-891e-4481-aeae-ccc5a2aa5f7a/金朵云logo左右结构-白色.png';
        return UserStoreModule.LogUrl || 'https://jdy-rd-dev.oss-cn-hangzhou.aliyuncs.com/workflow/65165826-891e-4481-aeae-ccc5a2aa5f7a/金朵云logo左右结构-白色.png';
    }

    /**
     * 获取登录的用户名
     */
    get UserName() {
        return UserStoreModule.UserName;
    }

    /**
     * 默认打开的一级菜单项
     */
    get DefaultOpenMenuKey() {
        if (this.collapsed) {
            return [];
        }
        const openMenuKey = this.$route.meta.parentMenu || this.getParentMenu(this.$route.path);
        if (openMenuKey) {
            return [openMenuKey];
        }
        return [];
    }

    /**
     * 获取左侧菜单展示/收起状态的图标
     */
    get CollapsedIcon() {
        return this.collapsed ? 'menu-unfold' : 'menu-fold';
    }
    get unReadNum(): number {
        return UserStoreModule.unReadMessagesNum;
    }
    // 全部消息已读
    getReadAllNews(): void {
        if (!this.unReadNum) return;
        CommonService.toReadALLMessages({ readAll: true });
        UserStoreModule.SET_UN_READ_MESSAGE_NUM(0);
        this.newestReadList = _.map(_.cloneDeep(this.newestReadList), item => {
            item.readStatus = 1;
            return item;
        });
        // 发布消息状态更新
        this.$eventHub.$emit('UPDATE_MESSAGE_STATUS');
    }
    // 打开单个消息
    async openMessage(index: number, info: any) {
        await Modal.destroyAll(); // 先销毁所有modal
        // 如果未读消息大于触发
        if (!info.readStatus) CommonService.toReadALLMessages({ ids: [info.id] });
        const h = this.$createElement;
        // modal 配置参数
        const modalOptions:any = {
            title: info.title || '-',
            icon: 'none',
            class: 'message-modal-container',
            closable: true,
            content: () => h('div', { class: 'message-content-list' },
            // 先展示简要内容如果没有展示主要内容
                info.summary || info.bodies.reduce((pre, item) => {
                    pre.push(h('div', { class: ' message-content-item item-body-content' }, `${item.label}: ${item.value || '-'}`));
                    return pre;
                }, [])
            ),
            okText: '查看详情',
            onOk: () => {
                if (info.bizType === 'ALARM' && info.bizIdentifier) {
                    this.$router.replace({ path: `/message/message-alarm-detail/${info.bizIdentifier}` });
                } else if (info.bizType === 'WORK_ORDER' && info.bizIdentifier) {
                    if (JTL.moduleType === ModuleType.WORKORDER) {
                        this.$router.replace({ path: `/dashboard/workorder-detail?woId=${info.bizIdentifier}&viewType=2` });
                    } else {
                        AppService.getAllApp().then(res => {
                            const v = _.find(res, item => item.identifier === 'app-workorder');
                            window.open(`/web/${v.identifier}/#/dashboard/workorder-detail?woId=${info.bizIdentifier}&viewType=2`);
                        });
                    }
                } else {
                    this.$router.push({ path: `/message/message-center` });
                }
                modal.destroy();
            }
        };
        const modal = Modal.success(modalOptions);
        this.newestReadList = _.map(_.cloneDeep(this.newestReadList), (item, inx) => {
            if (index === inx && item.readStatus === 0) {
                item.readStatus = 1;
                this.unReadNum > 0 ? UserStoreModule.SET_UN_READ_MESSAGE_NUM(this.unReadNum - 1) : '';
                // 发布消息状态更新
                this.$eventHub.$emit('UPDATE_MESSAGE_STATUS');
            }
            return item;
        });
    }
    /**
     * 判断菜单是否被选中
     */
    isMenuSelected(menuRoute: string): boolean {
        return this.$route.meta.parentPath === menuRoute || this.$route.meta.parentMenu === menuRoute || this.$route.path === menuRoute || this.getParentMenu(this.$route.path) === menuRoute;
    }

    /**
     * 菜单点击事件
     */
    menuClick(menu: {name: string, route: string}) {
        this.$router.push(menu.route);
    }

    /**
     * 返回企业
     */
    async backToCorpClick() {
        UserStoreModule.SET_CURRENT_PROJECT(null);
        await CommonService.getUserPermissions2(0);
        // this.$router.push('/dashboard/index');
        this.$router.push(UserStoreModule.RedirectPath);
    }

    /**
     * 退出登录
     */
    loginOutClick() {
        CacheUtil.cleanCache();
        UserStoreModule.SET_USERINFO();
        UserStoreModule.SET_REDIRECT_PATH();
        // UserStoreModule.SET_CURRENT_PROJECT(null);
        this.$router.push('/sign/login');
    }

    /**
     * 选择项目点击事件
     */
    async selectProjectClick(projectModel: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(projectModel);
        await CommonService.getUserPermissions2(0);
        this.$router.push(UserStoreModule.RedirectPath);
        this.getMessagesInfo();
    }

    /**
     * 初始化项目列表
     */
    initProjectList() {
        if (!this.IsSuperAdmin) {
            ProjectService.getAllProject(0).then(res => {
                this.projectList = res;
                initProject();
            });
        }
    }

    /**
     * 获取父级菜单的路由名
     */
    private getParentMenu(routePath: string) {
        if (!MENUS || MENUS.length === 0) {
            return;
        }
        let parentMenu: string = null;
        for (let i = 0; i < MENUS.length; i++) {
            if (MENUS[i].route === routePath) {
                return routePath;
            }
            if (MENUS[i].subMenus && MENUS[i].subMenus.length > 0) {
                for (let j = 0; j < MENUS[i].subMenus.length; j++) {
                    if (MENUS[i].subMenus[j].route === routePath) {
                        return MENUS[i].route;
                    }
                }
            }
        }
        return parentMenu;
    }

    onThemeChange(themeColor: string) {
        if (['dark', 'light'].indexOf(themeColor) > -1) {
            this.theme = themeColor;
            window.localStorage.setItem('theme', themeColor);
            return;
        }
        document.body.style.setProperty('--primaryColor', themeColor);
        window['less'].modifyVars({
            '@primary-color': themeColor
        }).then(() => { console.log('success'); }).catch(err => console.log(err));
        localStorage.setItem('PRIMARY_COLOR', themeColor);
    }

    menuCollapseClick() {
        this.collapsed = !this.collapsed;
        this.$eventHub.$emit('DASHBOARD_MENU_CHANGE', this.collapsed);
    }
}

